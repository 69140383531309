<template>
  <div class="home">
    <headert></headert>
    <el-row class="content">
      <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
        <div class="top-banner">
          <img src="../../assets/image/banner/landed.png" alt="">
        </div>
      </el-col>
    </el-row>
    <navMenu :activeIndex='menuActiveIndex' :menu='menu' :breadcrumb='breadcrumb' />
    <el-row class="houseDetail padding-tb70" type="flex" justify="center">
      <el-col :xs="23" :sm="23" :md="23" :lg="15" :xl="15" class="rightIno paddingb150 ">
        <div class="flex justify_between top-msg-detail">
          <div class="houseItitle flex align-end flex1">
            <img class="houseItitleImg1" src="../../assets/image/Industry/img2.png" alt="">
            <h6>{{news.title}}</h6>
            <p>{{news.subtitle}}<img class="houseItitleImg2" src="../../assets/image/Industry/Star1.png" alt=""></p>
          </div>
          <div class="detailMsgBox">
            <p class="flex align-start"><img src="../../assets/image/Industry/img12.png" alt="">{{$t('property.houseAddress')}}{{news.address}}</p>
            <p class="flex align-start" style="margin-bottom: 0;"><img src="../../assets/image/Industry/img13.png"
                alt="">{{$t('property.housePhone')}}{{news.phone}}</p>
          </div>
        </div>
        <div>
          <!-- <div class="detailSwiperBox flex" v-if="news.img_list && news.img_list.length>0">
            <div class="leftSwiper" v-for="(item,index) in news.img_list" :key="index">
              <img :src="item.image_url" alt="">
            </div>

          </div> -->
          <div class="picSlideWr">
            <div class="bd">
              <div class="tempWrap" ref="tempWrap" style="overflow:hidden; position:relative; "
                :style="{ 'width':tempwidth+'px'}">
                <ul style="  overflow: hidden; padding: 0px; margin: 0px; "
                  :style="{transform:'translate('+ulLeft+'px,0)',width:tempwidth*2+'px',transition:slide+'s'}">
                  <li class="clone" ref='libox' :style="{float: 'left', width: (liWidth-10)+'px'}" v-for="(item,index) in news.img_list"
                    :key="index">
                    <img style="width: 100%" :src="item.image_url" alt="">
                  </li>
                  <li class="clone" ref='libox' :style="{float: 'left', width: (liWidth-10)+'px'}" v-for="(item,index) in news.img_list"
                    :key="index">
                    <img style="width: 100%" :src="item.image_url" alt="">
                  </li>
                </ul>
              </div>
            </div>
            <div class="pon_box" @click="showMore">
              <a href="javascript:void(0);" class="next"></a>
            </div>
          </div>
          <div class="WJ-font">
            <div v-html="news.content"></div>
          </div>
        </div>
      </el-col>
    </el-row>
    <!-- 底部 -->
    <footerb></footerb>
  </div>
</template>

<script>
  export default {
    name: "Home",
    components: {},
    data() {
      return {
        swiperOptions: {
          autoplay: {
            delay: 1000,
            disableOnInteraction: true
          },

          loop: true,
          effect: "fade",
          fade: {
            slideShadows: true,
            shadow: true,
            shadowOffset: 100,
            shadowScale: 0.6
          },
          navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev"
          },
          pagination: {
            el: ".swiper-pagination",
            type: "bullets",
            clickable: true
            // progressbarOpposite: true,
          }
        },

        menuActiveIndex: '/Industry',
        menu: {
          name: '产业布局',
          path: '/Industry',
          list: [{
            name: this.$t('menu.Property'),
            path: '/IndustryHouse',
          }, {
            name: this.$t('menu.park'),
            path: '/EntertainmentIndustrialPark',
          }, {
            name: this.$t('menu.entertainment'),
            path: '/Entertainment',
          }, {
            name: this.$t('menu.Commerce'),
            path: '/IndustryCommerce',
          }]
        },
        breadcrumb: [{
          name: this.$t('menu.layout'),
          path: '/Industry',
        }, {
          name: this.$t('menu.Property'),
          path: '/Industry',
        }, {
          name: this.$t('property.href1'),
          path: '/IndustryHouse',
        }],
        id: null,
        news: {},
        tempwidth: 0,
        ulLeft: 0,
        ulIndex: 0,
        slide: 0,
        liWidth:719
      };
    },
    computed: {},
    created() {
      this.id = this.$route.query.id;
      if(document.documentElement.clientWidth<750){
        this.liWidth=document.documentElement.clientWidth-10
      }
      this.getData();
    },
    mounted() {
      new this.$wow.WOW().init();

    },
    methods: {
      showMore() {
        let total = this.news.img_list.length * 2
        if (this.ulIndex < total - 2) {
          this.ulIndex++;
          this.ulLeft -= this.liWidth;
          this.slide = 2;
        } else {
          this.slide = 0;
          this.ulIndex = 0;
          this.ulLeft = 0;
          // setTimeout(()=>{
          //   this.slide = 2;
          //    this.ulLeft = 0;
          // },20)

        }


      },
      getData() {
        let api = '?m=api&c=v1.Api&a=index&_ajax=1';
        this.$request.post(api, {
          apiArcview_1: `ekey=1&aid=${this.id},title_en,subtitle_en,content_en`, //获取文章详情
        }).then(res => {
          if (res.apiArcview[1].data) {
            this.news = this.$utils.translate(res.apiArcview[1].data);
            console.log(this.news )
            let imgList = this.news.img_list
            this.$nextTick(() => {
              this.tempwidth = this.liWidth * imgList.length

            })

          }
        })
      },
      goto(url) {
        this.$router.push(url)
      },
      onSwiper(swiper) {
        console.log(swiper);
      },
      onSlideChange() {
        console.log("slide change");
      }
    }
  };
</script>
<style lang="less" scoped>
  .swiperIMg {
    width: 100%;
    height: auto;

  }

  .swiper-container {
    width: 100%;
    max-height: 670px;
  }

  .content {
    overflow: hidden;
  }

  .houseDetail {
    background: url('../../assets/image/Industry/bg11.png') no-repeat;
    background-position: bottom left;

  }

  .leftIno {
    width: 312px;

    .noactive {
      display: inline-block;
      width: 100%;
      height: 116px;
      background: linear-gradient(90deg, #ADAFB2 0%, #8F9294 100%);
      // font-family: 'OPPOSans-M';
      font-style: normal;
      font-weight: 400;
      font-size: 29px;
      line-height: 40px;
      margin-bottom: 1px;
      padding-left: 50px;
      color: #FFFFFF;
      display: flex;
      align-items: center;

    }

    .activeBox {
      height: 162px;
      background: linear-gradient(90deg, #E3CAA6 0%, #B5956F 100%);
      position: relative;

      &::before {
        content: "";
        position: absolute;
        top: 28px;
        right: 0;
        width: 0;
        height: 0;
        border-right: 15px solid #fff;
        border-bottom: 15px solid transparent;
        border-top: 15px solid transparent;
        border-left: 15px solid transparent;
      }
    }
  }

  .houseItitle {
    position: relative;
    padding-left: 26px;
    position: relative;
  }

  .houseItitle h6 {
    font-family: 'Source Han Serif CN';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 87px;
    color: #1F2D39;
  }

  .houseItitle .houseItitleImg1 {
    position: absolute;
    top: 0;
    left: 0;
    width: 86px;
    height: 93px;
  }

  .houseItitle p {
    position: absolute;
    top:58px;
    left: 170px;
    // font-family: 'OPPOSans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 32px;
    color: #8F6B4B;
    margin-left: 21px;
    //position: relative;
  }

  .houseItitle p::before {
    content: '';
    position: absolute;
    top: 10px;
    left: -20px;
    width: 38px;
    border-top: 1px solid #D5B88E;
    transform: rotate(-45deg);
  }

  .houseItitleImg2 {
    position: absolute;
    width: 91px;
    height: 12px;
    bottom: -6px;
    right: 0;
  }

  .IndustryLookMore {

    width: 218px;
    height: 51px;
    border: 1px solid #A4825F;
    border-radius: 73px;
    margin: 88px auto 61px;
    // font-family: 'OPPOSans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 51px;
    text-align: center;
    color: #A4825F;
    cursor: pointer;
  }

  .detailMsgBox img {
    width: 24px;
    height: 24px;
    margin-right: 14px;
  }

  .detailMsgBox p {
    // font-family: 'OPPOSans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    margin-bottom: 15px;
  }

  .detailSwiperBox {
    width: 100%;
    padding: 48px 0 62px;
  }

  .leftSwiper {
    width: 59.5%;
    padding-top: 42.5%;
    position: relative;
    overflow: hidden;
  }

  .leftSwiper img {
    position: absolute;
    top: 0;
    left: 0;
    // width: 100%;
    height: 100%;
  }

  .rightSwiper {
    width: 41%;
    padding-top: 42.5%;
    position: relative;
    overflow: hidden;
    margin-left: 10px;
  }

  .rightSwiper img {
    position: absolute;
    top: 0;
    left: 0;
    // width: 100%;
    height: 100%;
  }

  .picSlideWr {
    position: relative;
    height: 497px;
    overflow: hidden;
    margin-bottom: 40px;
    margin-top: 48px;
  }

  .picSlideWr .bd li {
    float: left;
    margin-right: 10px;
    height: 497px;
    width: 709px;
    transition: 1s;
  }

  .picSlideWr .pon_box {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 5;
    height: 497px;
    width: calc(100% - 719px);
    background: url(../../assets/image/opacity_bg.png);
  }

  .picSlideWr .pon_box .next {
    position: absolute;
    left: 50%;
    top: 50%;
    margin: -34px 0 0 -34px;
    width: 68px;
    height: 68px;
    background: url(../../assets/image/nextIocn.png) no-repeat center;
  }
  @media screen and (min-width: 320px) and (max-width: 767px) {
    .top-msg-detail{
      display: block;
      .houseItitle{
        h6{
          font-size: 39px;
        }
        p{
          top:78px;
          left: 120px;
          font-size: 34px;
          line-height: 40px;
        }
        margin-bottom: 90px;
      }
      .detailMsgBox p{
        font-size: 34px;
        line-height: 53px;
        img{
          width: 44px;
          height: 44px;
        }
      }
    }
    .WJ-font div, .WJ-font p{
      font-size: 34px;
      line-height: 52px;
    }

    .houseDetail{
      min-height: calc(100vh - 400px);
    }
  }


</style>
